import styled from '@emotion/styled'

const TitleBlock = styled.h2`
  font-size: 36px;
  color: ${props => props.theme.colors.fontDark};
  text-align: center;
  font-weight: 800;
  margin: 0 0 70px 0;
  position: relative;
  padding: 0 0 20px 0;
  :after {
    content: '';
    display: block;
    width: 100px;
    height: 5px;
    background: ${props => props.theme.colors.borderTitle};
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0 0 0 -50px;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font-size: 25px;
    margin: 0 0 50px 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    font-size: 20px;
  }
`

export default TitleBlock
