import React from 'react'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { LocaleContext } from './Layout'
import Wrapper from './Wrapper'
import TitleBlock from './TitleBlock'
import { getContentByLocale, getFormulaSlag, renderFormula } from '../utils/utils'
import TextBlock from './TextBlock'
import CountriesGroupList from './CountriesGroupList'
import CountriesListForPost from './CountriesListForPost'

const Root = styled.div`
  padding: 70px 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 50px 0;
    text-align: center;
  }
`

const Countries = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
`

const TitleForPost = styled.ul`
  font-size: 26px;
  text-align: center;
  font-weight: 700;
  margin: 0 0 70px 0;
  position: relative;
  padding: 0 0 20px 0;
  :after {
    content: '';
    display: block;
    width: 100px;
    height: 5px;
    background: ${props => props.theme.colors.borderTitle};
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0 0 0 -50px;
  }
`

const groupCountries = countries => {
  const result = countries.reduce((r, e) => {
    const group = e.node.data.full_name[0]
    if (!r[group]) {
      r[group] = { group, countries: [e] }
    } else {
      r[group].countries.push(e)
    }
    return r
  }, {})
  return Object.values(result)
}

const CountryBlock = ({ post, device, kind }) => {
  const lang = React.useContext(LocaleContext)
  const { block, countries, generators } = useStaticQuery(query)
  const blockByLocale = getContentByLocale(block.edges, lang.locale)
  // eslint-disable-next-line camelcase
  const { heading,  heading_block_for_post_device, heading_block_for_post_country, text, link } = blockByLocale[0].node.data
  const formula = getFormulaSlag(generators, lang.locale)
  const countriesByLocale = getContentByLocale(countries.edges, lang.locale)
  const countriesGroupList = groupCountries(countriesByLocale)
  const titleForPostBlock = renderFormula(heading_block_for_post_device.text, {
    device,
  })
  return (
    <Root>
      <Wrapper id={link}>
        {!post && <TitleBlock>{heading}</TitleBlock>}
        {post && kind === 'device' && <TitleForPost>{titleForPostBlock}</TitleForPost>}
        {post && kind === 'country' && <TitleForPost>{heading_block_for_post_country.text}</TitleForPost>}
        {!post && <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} />}
        {!post ? (
          <Countries>
            {countriesGroupList.map(c => (
              <CountriesGroupList key={c.group} {...c} formula={formula} />
            ))}
          </Countries>
        ) : (
          <Countries>
            <CountriesListForPost countriesList={countriesByLocale} formula={formula} />
          </Countries>
        )}
      </Wrapper>
    </Root>
  )
}

const query = graphql`
  query CountryListQuery {
    block: allPrismicBlockCountries {
      edges {
        node {
          lang
          data {
            heading
            heading_block_for_post_country {
              text
            }
            heading_block_for_post_device {
              text
            }
            link
            text {
              html
            }
          }
        }
      }
    }
    generators: allPrismicGenerator(filter: { uid: { eq: "country" } }) {
      edges {
        node {
          data {
            formula
            formula_slug
          }
          lang
          uid
        }
      }
    }
    countries: allPrismicPostCountry(sort: { order: ASC, fields: data___full_name }) {
      edges {
        node {
          lang
          data {
            full_name
            country {
              document {
                uid
                data {
                  full_name
                  alt_preposition
                  alt_name
                  iso_code
                }
              }
            }
            body {
              ... on PrismicPostCountryBodyText {
                slice_type
              }
            }
          }
        }
      }
    }
  }
`

CountryBlock.propTypes = {
  post: PropTypes.bool,
  device: PropTypes.string,
  kind: PropTypes.string,
}

CountryBlock.defaultProps = {
  post: false,
  device: '',
  kind: '',
}

export default CountryBlock
