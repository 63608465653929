import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import loadable from '@loadable/component'
import LocalizedLink from './LocalizedLink'
import { renderSlug } from '../utils/utils'
import { localizedSlug } from '../utils/gatsby-node-helpers'

const TinyFlag = loadable(() => import('tiny-flag-react'))
const QUANTITY_RANDOM_DEVICES_IN_POST = 12

let imageUrlCdn = 'https://cdn.esim.ninja'

if (process.env.NODE_ENV !== `production`) {
  imageUrlCdn = 'https://cdn.test.esim.ninja'
}

const PostLink = styled.p`
  color: ${props => props.theme.colors.fontDark};
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  max-width: 23%;
  flex: 1 0 25%;
  text-align: left;
  display: flex;
  align-items: center;
  margin: 0 2.6% 0 0;
  :nth-child(4n) {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex: 1 0 50%;
    max-width: 48%;
    width: auto;
    margin: 0 4% 0 0;
    :nth-child(2n) {
      margin: 0;
    }
  }
`

const getCountryListForPost = (countryList, quantity) => {
  const localArray = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < quantity; i++) {
    // eslint-disable-next-line no-bitwise
    const randomElement = (countryList.length * Math.random()) << 0
    if (
      localArray.some(
        ({ node }) => node.data.country.document[0].uid === countryList[randomElement].node.data.country.document[0].uid
      )
    ) {
      // eslint-disable-next-line no-param-reassign,no-plusplus
      quantity++
    } else {
      localArray.push(countryList[randomElement])
    }
    if (localArray.length === 0) localArray.push(countryList[randomElement])
  }
  return localArray
}

const CountriesListForPost = ({ countriesList, formula }) => {
  const countryListRandom = getCountryListForPost(countriesList, QUANTITY_RANDOM_DEVICES_IN_POST)
  return (
    <>
      {countryListRandom.map(({ node }) => {
        const {
          // eslint-disable-next-line camelcase
          data: { full_name, country },
        } = node
        const {
          uid,
          // eslint-disable-next-line camelcase
          data: { iso_code },
        } = country.document[0]
        const postUrl = renderSlug(formula, uid)
        return (
          <PostLink>
            <TinyFlag country={iso_code} fallbackImageURL={`${imageUrlCdn}/flags/${iso_code}.svg`} />
            &nbsp;
            {postUrl ? (
              // eslint-disable-next-line camelcase
              <LocalizedLink to={localizedSlug({ uid: postUrl })}>{full_name}</LocalizedLink>
            ) : (
              // eslint-disable-next-line camelcase
              full_name
            )}
          </PostLink>
        )
      })}
    </>
  )
}

CountriesListForPost.propTypes = {
  countriesList: PropTypes.array.isRequired,
  formula: PropTypes.string.isRequired,
}

export default CountriesListForPost
