import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import loadable from '@loadable/component'
import LocalizedLink from './LocalizedLink'
import { localizedSlug } from '../utils/gatsby-node-helpers'
import { renderSlug } from '../utils/utils'

const TinyFlag = loadable(() => import('tiny-flag-react'))

let imageUrlCdn = 'https://cdn.esim.ninja'

if (process.env.NODE_ENV !== `production`) {
  imageUrlCdn = 'https://cdn.test.esim.ninja'
}

const CountriesGroup = styled.li`
  border-top: 2px #cecece solid;
  padding: 20px 0 0 0;
  max-width: 23%;
  flex: 1 0 25%;
  margin: 0 2.6% 0 0;
  font-size: 20px;
  font-weight: 700;
  color: ${props => props.theme.colors.fontDark};
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: left;
  }
  :nth-child(4n) {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex: 1 0 50%;
    max-width: 48%;
    width: auto;
    margin: 0 4% 0 0;
    :nth-child(2n) {
      margin: 0;
    }
  }
  ul {
    margin: 15px 0 40px 0;
    list-style: none;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      text-align: left;
    }
    li {
      color: ${props => props.theme.colors.fontDark};
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }
`

const isPostNotEmpty = ({
  node: {
    data: { body },
  },
}) => body && !!body.filter(({ slice_type }) => slice_type === 'text')[0]

const CountriesGroupList = ({ group, countries, formula }) => (
  <CountriesGroup>
    {group}
    <ul>
      {countries.map(c => {
        const {
          node: {
            // eslint-disable-next-line camelcase
            data: { full_name, country },
          },
        } = c
        const {
          uid,
          // eslint-disable-next-line camelcase
          data: { iso_code },
        } = country.document[0]
        const postUrl = renderSlug(formula, uid)
        return (
          // eslint-disable-next-line camelcase
          <li key={full_name}>
            <TinyFlag country={iso_code} fallbackImageURL={`${imageUrlCdn}/flags/${iso_code}.svg`} />
            &nbsp;
            {isPostNotEmpty(c) ? (
              // eslint-disable-next-line camelcase
              <LocalizedLink to={localizedSlug({ uid: postUrl })}>{full_name}</LocalizedLink>
            ) : (
              // eslint-disable-next-line camelcase
              full_name
            )}
          </li>
        )
      })}
    </ul>
  </CountriesGroup>
)

CountriesGroupList.propTypes = {
  group: PropTypes.string.isRequired,
  countries: PropTypes.array.isRequired,
  formula: PropTypes.string.isRequired,
}

export default CountriesGroupList
