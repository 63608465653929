import React from 'react'
import { toLower } from 'lodash'
import styled from '@emotion/styled'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { LocaleContext } from './Layout'
import Wrapper from './Wrapper'
import TitleBlock from './TitleBlock'
import { localizedSlug } from '../utils/gatsby-node-helpers'
import { getContentByLocale, getFormulaSlag, renderFormula, isPostNotEmpty, renderSlug } from '../utils/utils'
import TextBlock from './TextBlock'
import VendorDevicesList from './VendorDevicesList'
import DevicesListForPost from './DevicesListForPost'

const Root = styled.div`
  padding: 70px 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 50px 0;
    text-align: center;
  }
`
const Devices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const TitleForPost = styled.ul`
  font-size: 26px;
  text-align: center;
  font-weight: 700;
  margin: 0 0 70px 0;
  position: relative;
  padding: 0 0 20px 0;
  :after {
    content: '';
    display: block;
    width: 100px;
    height: 5px;
    background: ${props => props.theme.colors.borderTitle};
    position: absolute;
    bottom: 0;
    left: 50%;
    margin: 0 0 0 -50px;
  }
`

const getVendorList = (prismicVendors, locale) => {
  const { edges } = prismicVendors
  const result = []
  if (edges && edges.length) {
    edges.forEach(edge => {
      const {
        node: {
          data: { vendors },
        },
      } = edge
      vendors.forEach(({ vendor: { document: d } }) => {
        const {
          lang,
          // eslint-disable-next-line camelcase
          data: { full_name },
        } = d[0]
        if (locale === lang) {
          result.push(full_name)
        }
      })
    })
  }
  return result
}

const getVendorDevicesList = (devices, vendors) =>
  vendors.map(vendor => {
    const vendorDevices = devices.filter(device => device.vendor === vendor)
    return { vendor, devices: vendorDevices }
  })
const getDevicesList = (devices, formula) =>
  devices.map(({ node }) => {
    const vendor = node.data.vendor.document[0].data.full_name
    const { model, full_name: fullName, body } = node.data
    const uid = renderSlug(formula, null, null, toLower(`${vendor} ${model}`).replace(/[ .,]/g, '-'))
    const url = isPostNotEmpty(body) && localizedSlug({ uid })
    return { vendor, model, fullName, uid, url }
  })

const SupportedDevicesBlock = ({ post, device }) => {
  const lang = React.useContext(LocaleContext)
  const { vendors, devices, generator } = useStaticQuery(query)
  const vendorsByLocale = getContentByLocale(vendors.edges, lang.locale)
  // eslint-disable-next-line camelcase
  const { heading, heading_block_for_post, text, link } = vendorsByLocale[0].node.data
  const deviceByLocale = getContentByLocale(devices.edges, lang.locale)
  const deviceFormulaSlug = getFormulaSlag(generator, lang.locale)
  const deviceList = getDevicesList(deviceByLocale, deviceFormulaSlug)
  const vendorList = getVendorList(vendors, lang.locale)
  const list = getVendorDevicesList(deviceList, vendorList)
  const titleForPostBlock = renderFormula(heading_block_for_post.text, {
    device,
  })
  return (
    <Root>
      <Wrapper id={link}>
        {!post ? <TitleBlock>{heading.text}</TitleBlock> : <TitleForPost>{titleForPostBlock}</TitleForPost>}
        {!post && <TextBlock dangerouslySetInnerHTML={{ __html: text.html }} />}
        {!post ? (
          <Devices>
            {list.map(v => !!v.devices && !!v.devices.length && <VendorDevicesList key={v.vendor} {...v} />)}
          </Devices>
        ) : (
          <Devices>
            <DevicesListForPost deviceList={deviceList} />
          </Devices>
        )}
      </Wrapper>
    </Root>
  )
}

const query = graphql`
  query SupportedDevicesQuery {
    vendors: allPrismicBlockDevices {
      edges {
        node {
          id
          uid
          data {
            text {
              html
            }
            heading {
              text
            }
            heading_block_for_post {
              text
            }
            link
            vendors {
              vendor {
                document {
                  lang
                  data {
                    full_name
                  }
                }
              }
            }
          }
          lang
        }
      }
    }
    generator: allPrismicGenerator(filter: { uid: { eq: "device" } }) {
      edges {
        node {
          lang
          data {
            formula_slug
          }
        }
      }
    }
    devices: allPrismicPostDevice(sort: { order: DESC, fields: data___model }) {
      edges {
        node {
          data {
            model
            full_name
            vendor {
              document {
                data {
                  full_name
                }
              }
            }
            body {
              ... on PrismicPostDeviceBodyText {
                slice_type
                primary {
                  text {
                    text
                  }
                }
              }
            }
          }
          lang
        }
      }
    }
  }
`

SupportedDevicesBlock.propTypes = {
  post: PropTypes.bool,
  device: PropTypes.string,
}
SupportedDevicesBlock.defaultProps = {
  post: false,
  device: '',
}

export default SupportedDevicesBlock
