import styled from '@emotion/styled'

const TextBlock = styled.div`
  p {
    font-size: 20px;
    line-height: 1.2;
    font-weight: 400;
    margin: 0 0 50px 0;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 15px;
    }
  }
`

export default TextBlock
