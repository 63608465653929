import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import LocalizedLink from './LocalizedLink'

const Device = styled.div`
  border-top: 2px #cecece solid;
  padding: 20px 0 0 0;
  max-width: 23%;
  flex: 1 0 25%;
  margin: 0 2.6% 0 0;
  :nth-child(4n) {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex: 1 0 50%;
    max-width: 48%;
    width: auto;
    margin: 0 4% 0 0;
    :nth-child(2n) {
      margin: 0;
    }
  }
  h3 {
    font-size: 20px;
    margin-bottom: 15px;
    color: ${props => props.theme.colors.fontDark};
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      text-align: left;
    }
  }
  ul {
    margin: 0;
    list-style: none;
    margin-bottom: 40px;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      text-align: left;
    }
    li {
      color: ${props => props.theme.colors.fontDark};
      font-size: 15px;
      font-weight: 500;
      line-height: 25px;
      margin: 0;
    }
  }
`

const VendorDevicesList = ({ vendor, devices }) => (
  <Device>
    <h3>{vendor}</h3>
    <ul>
      {devices.map(({ fullName, uid, url }) => (
        <li key={uid}>{url ? <LocalizedLink to={url}>{fullName}</LocalizedLink> : fullName}</li>
      ))}
    </ul>
  </Device>
)

VendorDevicesList.propTypes = {
  vendor: PropTypes.string.isRequired,
  devices: PropTypes.array.isRequired,
}

export default VendorDevicesList
