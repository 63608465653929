import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import LocalizedLink from './LocalizedLink'

const QUANTITY_RANDOM_DEVICES_IN_POST = 12

const PostLink = styled.p`
  color: ${props => props.theme.colors.fontDark};
  font-size: 15px;
  font-weight: 500;
  line-height: 25px;
  max-width: 23%;
  flex: 1 0 25%;
  margin: 0 2.6% 0 0;
  text-align: left;
  :nth-child(4n) {
    margin: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex: 1 0 50%;
    max-width: 48%;
    width: auto;
    margin: 0 4% 0 0;
    :nth-child(2n) {
      margin: 0;
    }
  }
`

const getVendorDevicesListForPost = (deviceList, quantity) => {
  const localArray = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < quantity; i++) {
    // eslint-disable-next-line no-bitwise
    const randomElement = (deviceList.length * Math.random()) << 0
    if (localArray.some(({ uid }) => uid === deviceList[randomElement].uid)) {
      // eslint-disable-next-line no-param-reassign,no-plusplus
      quantity++
    } else {
      localArray.push(deviceList[randomElement])
    }
    if (localArray.length === 0) localArray.push(deviceList[randomElement])
  }
  return localArray
}

const DevicesListForPost = ({ deviceList }) => {
  const deviceListRandom = getVendorDevicesListForPost(deviceList, QUANTITY_RANDOM_DEVICES_IN_POST)
  return (
    <>
      {deviceListRandom.map(v => (
        <PostLink>{v.url ? <LocalizedLink to={v.url}>{v.fullName}</LocalizedLink> : v.fullName}</PostLink>
      ))}
    </>
  )
}

DevicesListForPost.propTypes = {
  deviceList: PropTypes.array.isRequired,
}

export default DevicesListForPost
