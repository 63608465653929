export const getContentByLocale = (edges, locale) => edges.filter(({ node: { lang } }) => lang === locale)

export const getFormulaSlag = (generator, locale) => {
  let formula = null
  // eslint-disable-next-line camelcase
  generator.edges.forEach(({ node: { lang, data: { formula_slug } } }) => {
    if (locale === lang) {
      // eslint-disable-next-line camelcase
      formula = formula_slug
    }
  })
  return formula
}

// eslint-disable-next-line no-eval
export const renderSlug = (generator, country, operator, device) => eval(`\`${generator}\``)

export const renderFormula = (f, p) => {
  const { country, operator, device, preposition, colon } = p
  // eslint-disable-next-line no-eval
  return eval(`\`${f}\``)
}

export const isPostNotEmpty = body => {
  if (body && body.length) {
    const textSlices = body.filter(s => s.slice_type === 'text')
    if (textSlices && textSlices.length) {
      const filledTextSlices = textSlices.filter(s => !!s.primary && !!s.primary.text && !!s.primary.text.text)
      return !!filledTextSlices.length
    }
  }
  return false
}
